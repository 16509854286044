<template>
  <article class="content-section">
    <div class="educourse-list-container">
      <!-- educourse-list -->

      <div v-if="items.length === 0" class="search-container">
        <div class="result-empty">
          <img src="@/assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">{{descTitle}} 연수가 없습니다.</p>
        </div>
      </div>
      <ul v-else class="educourse-list">
        <!-- educourse-list > list-item -->
        <li v-for="(item, index) in items" class="list-item" :key="index">
          <!-- educourse-list > educourse -->
          <div class="educourse">
            <div class="educourse-summary course-row">
              <article class="course-card">
                <div class="course-image">
                  <a href="javascript:" class="image-link" @click="goLearn(item)"></a>
                  <figure class="image-figure">
                    <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                    <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                    <CourseThumb v-else
                                 :num="item.crseMstSn"
                    />
                  </figure>
                </div>
                <div class="course-content">
                  <div class="content-header">
                    <h5 class="title">
                      <a href="javascript:" class="title-link" @click="goLearn(item)">{{ item.crseNm }}</a>
                    </h5>
                  </div>
                  <div class="content-footer">
                    <div class="content-meta">
                      <p class="text">{{  item.orgnzNm }}</p>
                      <p class="text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd')}} - {{timestampToDateFormat(item.endDt, 'yyyy.MM.dd')}}</p>
                    </div>
                    <div class="content-mileage">
<!--                      <p v-if="item.splCrseTyCdDcd === '2066015'"  class="text">-</p>-->
                      <p class="text" v-html="getFinishText(item)"></p>
                    </div>
                    <div class="course-icons">
                      <div class="icons">
                        <i class="icon-course-collective" title="집합연수"></i><i class="icon-course-book" title="교재"></i><i class="icon-course-cyber" title="사이버"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="course-actions">&nbsp;</div>
              </article>
            </div>
          </div>
        </li>
        <!-- educourse-list > list-item -->
      </ul>

    </div>
  </article>
</template>
<script>

import CourseThumb from '@/components/apply/CourseThumb';
import {dutyRegularCourseSetup} from "@/assets/js/modules/duty/duty-regular-setup";

export default {
  name: "MobileDutyRegularCourse",
  components: {CourseThumb},
  props: {
    title: String,
    items: Array
  },
  setup: dutyRegularCourseSetup
}
</script>
