import {ref} from 'vue';
import {useRouter} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  ACT_GET_CRSE_DIST_REGULAR_LIST,
} from '@/store/modules/course/course';
import {getListFunc} from '@/assets/js/ui.init';
import {
  sortCourseItems,
  sortKeyDesc,
} from '@/assets/js/modules/learn/learn-regular-sort';
import {numberComma, timestampToDateFormat} from "@/assets/js/util";
import {getThumbUrl} from "@/assets/js/modules/course/course-common";
import {goBack} from '@/assets/js/modules/common/common';

export const dutyRegularSetup = () => {
  const {showLoading, hideLoading} = useAlert();
  const currentYear = new Date().getFullYear();
  const router = useRouter();
  const isReady = ref(false);
  const items = ref([]);
  const categories = [
    {title: '의무교육', splCrseTyCdDcd: '2066024'},
  ];

  const renderItems = ref([]);
  showLoading();

  const goLearn = (item) => {
      router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }});
  }

  // router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }});

  getListFunc(`course/${ACT_GET_CRSE_DIST_REGULAR_LIST}`, {bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`, SplCrseTyCdDcd: `2066024`}, items, null, () => {
    // const distinctItems = distinctItems(items.value);
    renderItems.value = categories.map(x => (
        {
          ...x,
          items: x.splCrseTyCdDcd === '2066013' ? sortKeyDesc(sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd)), 'bgngDt') : sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd))
        }
    ));
    console.log(renderItems.value);

    hideLoading();
    isReady.value = true;
  });

  return {
    isReady,
    items,
    renderItems,
    goLearn,
    goBack
  }
}

export const dutyRegularCourseSetup = () => {
  const router = useRouter();
  const {showMessage} = useAlert();

  const goLearn = (item) => {
    if (item.bgngDt > new Date().getTime()) {
      showMessage(timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') + ' 부터 연수를 하실 수 있습니다.');
    } else if (item.endDt < new Date().getTime()) {
      showMessage('해당 연수는 ' + timestampToDateFormat(item.endDt, 'yyyy.MM.dd') + '일 부로 종료되었습니다.');
    } else {
      router.push({name: 'LearnMain', params: {distCrseSn: item.distCrseSn, view: 'home'}});
    }
  }

  const getFinishText = (item) => {
    if(item.lrnTrgtGrpDtlSn > 0 && item.learns.length > 0){
        return item.fnshYn === 'Y' ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
    }
    return '<span class="text-muted">미완료</span>';
  }

  return {
    goLearn,
    timestampToDateFormat,
    numberComma,
    getThumbUrl,
    getFinishText
  }

}