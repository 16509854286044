<template>
  <main class="kb-main" id="kb-educourse-status">
    <LxpMobileHeader title="의무교육 현황">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>

      </template>
    </LxpMobileHeader>
    <!-- main-content -->
<!--    <div v-for="(item, index) in items" :class="index > 0 ? 'mt-5' : ''" :key="index">-->
<!--      <MobileTrainRegularCourse-->
<!--          :title="item.title"-->
<!--          :items="item.items"-->
<!--      />-->
<!--    </div>-->
    <template v-if="isReady">
      <div v-for="(item, index) in renderItems" :class="index > 0 ? 'mt-5' : ''" :key="index">
        <MobileDutyRegularCourse
            :title="item.title"
            :items="item.items"
        />
      </div>
    </template>
    <br><br><br><br><br>
  </main>
</template>

<script>

import MobileDutyRegularCourse from '@/components/duty/mobile/MobileDutyRegularCourse';
import {dutyRegularSetup} from '@/assets/js/modules/duty/duty-regular-setup';
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";

export default {
  name: 'MobileDutyLrnStatus',
  components: {LxpMobileHeader, MobileDutyRegularCourse},
  setup: dutyRegularSetup
}
</script>